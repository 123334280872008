import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl as MuiFormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  Tabs,
  Box,
  Tab,
  Card,
  IconButton,
  CardContent,
  TextField,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 200px;
`;

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const columns = [
  {
    field: "targa",
    headerName: "Targa",
    width: 100,
    editable: true,
  },
  {
    field: "BOT",
    headerName: "BOT",
    width: 125,
    editable: true,
  },
  {
    field: "Note",
    headerName: "Note",
    editable: true,
    flex: 1,
  },
];

const DataGridTabella = ({ rows }) => {
  const [searchParam, setSearchParam] = useState("");
  const [filteredUsersList, setFilteredUsersList] = useState(rows);

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  useEffect(() => {
    const filteredList = rows.filter(
      (row) =>
        row.targa.includes(searchParam) ||
        row.BOT.includes(searchParam) ||
        row.Note.includes(searchParam)
    );
    setFilteredUsersList(filteredList);
  }, [searchParam, rows]);

  return (
    <Card mb={6}>
      <Box sx={{ display: "flex", justifyContent: "end", p: 0, my: 4 }}>
        <FormControl>
          <TextField
            size="small"
            margin="none"
            label="Ricerca"
            onChange={(e) => handleSearch(e)}
          />
        </FormControl>
      </Box>

      <CardContent sx={{ p: 0 }}>
        <div style={{ width: "100%" }}>
          <DataGrid
            rowsPerPageOptions={[5, 10, 25]}
            columns={columns}
            pageSize={5}
            getRowId={(row) => row.iduid}
            rowHeight={30}
            autoHeight
            headerHeight={30}
            rows={searchParam ? filteredUsersList : rows}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  iduid: false,
                },
              },
            }}
            autosizeOptions={{
              columns: ["Note"],
              includeOutliers: true,
              includeHeaders: true,
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

const Modale = ({
  open,
  setOpen,
  dati,
  openFrom,
  servizioProp,
  codiceServizioProp,
  botProp,
  idUidBotProp,
  statoProp,
  codiceStatoProp,
  scopeProp,
}) => {
  const isUnder600px = useMediaQuery("(max-width:600px)");
  const isUnder1000px = useMediaQuery("(max-width:1000px)");

  const [valueTabs, setValueTabs] = useState(0);
  const [servizio, setServizio] = useState();
  const [bot, setBot] = useState("");
  const [stato, setStato] = useState(statoProp);
  const [codiceServizio, setCodiceServizio] = useState();
  const [idUidBot, setIdUidBot] = useState();
  const [codiceStato, setCodiceStato] = useState();
  const [scope, setScope] = useState(scopeProp);

  const [totaleDati, setTotaleDati] = useState();
  const [dataArray, setDataArray] = useState([]);
  const navigate = useNavigate();

  const stati = [
    { nome: "Errore", codice: 4 },
    { nome: "Non Gestibile", codice: 5 },
    { nome: "Pending", codice: 2 },
  ];

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    if (openFrom === "TabellaRiepilogo") {
      setServizio("");
      setBot("");
      setStato(statoProp);

      setScope(scopeProp);
    } else {
      setServizio(servizioProp);
      setBot(botProp);
      setScope(scopeProp);
      if (botProp === bot) {
        setStato(statoProp);
      }
    }
    setTotaleDati(0);
    setDataArray([]);
  };

  const handleChangeScope = (event) => {
    setScope(event.target.value);
    setServizio("");
    setBot("");
  };

  const handleChangeServizio = (event) => {
    setServizio(event.target.value);
    setBot("");
  };

  const handleChangeBot = (event) => {
    setBot(event.target.value);
  };

  const handleChangeStato = (event) => {
    setStato(event.target.value);
  };

  const handleClickButton = () => {
    servicesGetDetails({
      codice: codiceServizio,
      idUIDBot: idUidBot,
      idStato: codiceStato,
    });
  };

  const handleClickServizio = (codice) => {
    setCodiceServizio(codice);
  };

  const handleClickBot = (idUid) => {
    setIdUidBot(idUid);
  };

  useEffect(() => {
    if (openFrom === "TabellaRiepilogo") {
      setStato(statoProp);
      setScope(scopeProp);
      /* if (scopeProp === "Ania") {
        setCodiceServizio(0);
        setIdUidBot("");
      } */
      setCodiceServizio(0);
      setIdUidBot("");
      setCodiceStato(codiceStatoProp);
      /* if (open) {
        servicesGetDetails({
          codice: 0,
          idUIDBot: "",
          idStato: codiceStatoProp,
        });
      } */
    } else {
      setServizio(servizioProp);
      setBot(botProp);
      setStato(statoProp);
      setScope(scopeProp);
      setCodiceServizio(codiceServizioProp);
      setIdUidBot(idUidBotProp);
      setCodiceStato(codiceStatoProp);
      if (open) {
        servicesGetDetails({
          codice: codiceServizioProp,
          idUIDBot: idUidBotProp,
          idStato: codiceStatoProp,
        });
      }
    }
  }, [
    openFrom,
    servizioProp,
    botProp,
    statoProp,
    codiceServizioProp,
    idUidBotProp,
    codiceStatoProp,
    scopeProp,
  ]);

  async function servicesGetDetails(data) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: data,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/servicesGetDetails",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === 0) {
        navigate("/private");
      }
      if (responseData.status === -1) {
        navigate("/auth/sign-in");
      }
      const array = responseData.data;
      if (!array) {
        navigate("/auth/sign-in");
      }
      setDataArray(array);
      setTotaleDati(array?.length);
    } catch (error) {
      return error;
    }
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": isUnder1000px
            ? {
                width: "100%",
                maxWidth: "900px", // Set your width here
              }
            : {
                width: "100%",
                maxWidth: "900px", // Set your width here
                maxHeight: "600px",
              },
        },
      }}
    >
      <DialogTitle>Focus Servizi</DialogTitle>
      <DialogContent>
        <form
          style={
            isUnder600px
              ? {
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }
              : { display: "flex" }
          }
        >
          <FormControl m={2} style={{ minWidth: 120 }} size="small">
            <InputLabel id="scope">Scope</InputLabel>
            <Select
              labelId="scope"
              id="scope"
              MenuProps={MenuProps}
              label="Scope"
              value={scope}
              size="small"
              onChange={handleChangeScope}
            >
              <MenuItem value="Preventivi">Preventivi</MenuItem>
              <MenuItem value="ania">Ania</MenuItem>
              <MenuItem value="Asset">Asset</MenuItem>
            </Select>
          </FormControl>
          <FormControl m={2} style={{ minWidth: 120 }} size="small">
            <InputLabel id="servizio">Servizio</InputLabel>
            <Select
              labelId="servizio"
              id="servizio"
              MenuProps={MenuProps}
              label="Servizio"
              value={servizio}
              size="small"
              onChange={handleChangeServizio}
            >
              <MenuItem
                onClick={() => handleClickServizio(0)}
                sx={{ height: 30 }}
              />
              {dati.map(
                (servizio) =>
                  servizio.ScopeDesc === scope && (
                    <MenuItem
                      key={servizio.Nome}
                      value={servizio.Nome}
                      onClick={() => handleClickServizio(servizio.Codice)}
                    >
                      {servizio.Nome}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
          <FormControl m={2} style={{ minWidth: 150 }} size="small">
            <InputLabel id="demo-mutiple-bot-label">Bot</InputLabel>
            <Select
              labelId="demo-mutiple-bot-label"
              id="demo-mutiple-bot"
              MenuProps={MenuProps}
              label="Bot"
              value={bot}
              size="small"
              onChange={handleChangeBot}
            >
              <MenuItem
                onClick={() => handleClickBot("")}
                sx={{ height: 30 }}
                value=""
              />
              {dati.map(
                (service) =>
                  service.Nome === servizio &&
                  service.dataset.map((bot) => (
                    <MenuItem
                      key={bot.nome}
                      value={bot.nome}
                      onClick={() => handleClickBot(bot.idUid)}
                    >
                      {bot.nome}
                    </MenuItem>
                  ))
              )}
            </Select>
          </FormControl>
          <FormControl m={2} style={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-mutiple-stato-label">Stato</InputLabel>
            <Select
              labelId="demo-mutiple-stato-label"
              id="demo-mutiple-stato"
              MenuProps={MenuProps}
              label="Stato"
              value={stato}
              size="small"
              onChange={handleChangeStato}
            >
              {stati.map((stato) => (
                <MenuItem
                  key={stato.nome}
                  value={stato.nome}
                  onClick={() => setCodiceStato(stato.codice)}
                >
                  {stato.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleClickButton}>
              <Search />
            </IconButton>
          </Box>
        </form>

        <Box sx={{ width: "100%" }} my={2}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
            >
              <Tab label={`Preventivi (${totaleDati ? totaleDati : 0})`} />
              <Tab label="Settings" />
            </Tabs>
          </Box>
          <CustomTabPanel value={valueTabs} index={0}>
            <DataGridTabella rows={dataArray} />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={1}>
            Item Two
          </CustomTabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{ marginRight: "8px" }}
        >
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Modale;
