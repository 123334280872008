import React, { useEffect } from "react";
import { Typography, Grid, FormControl, Divider, Spacer } from "../style";
import { TextField, Select, MenuItem, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const Cliente = ({
  clientPlcholders,
  handleInputChange,
  tabData,
  className,
  rcDetails,
}) => {
  useEffect(() => {
    // Popolare automaticamente i campi non editabili con i dati ricevuti dall'API
    if (rcDetails) {
      clientPlcholders.forEach((client) => {
        if (!client.editable && rcDetails.infoAnag[client.value]) {
          handleInputChange(
            "Cliente",
            client.value,
            rcDetails.infoAnag[client.value]
          );
        }
      });
    }
  }, [rcDetails, clientPlcholders, handleInputChange]);

  const handleDateChange = (date, fieldName) => {
    const formattedDate = date ? date.toLocaleDateString("it-IT") : "";
    handleInputChange("Cliente", fieldName, formattedDate);
  };

  return (
    <div className={className}>
      <Typography variant="h5" gutterBottom>
        Dati del cliente
      </Typography>
      <Spacer mb={5} />

      <Grid
        container
        spacing={2}
        wrap="wrap"
        sx={{ maxWidth: { xs: "calc(100vw - 60px)" } }}
      >
        {clientPlcholders.map((client) => (
          <Grid item sm={4} xs={12} key={client.value}>
            <FormControl fullWidth>
              {client.value === "Data Nascita" ? (
                <DatePicker
                  label={`${client.value} ${!client.editable ? "" : "*"}`}
                  name={client.value}
                  value={tabData.values[client.value] || null}
                  onChange={(date) => handleDateChange(date, client.value)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  error={Boolean(tabData.errors[client.value])}
                  helperText={tabData.errors[client.value]}
                  inputFormat="dd/MM/yyyy"
                />
              ) : client.value === "Sesso" ? (
                <FormControl fullWidth>
                  <InputLabel>{`${client.value} ${
                    !client.editable ? "" : "*"
                  }`}</InputLabel>
                  <Select
                    value={tabData.values[client.value] || ""}
                    onChange={(event) =>
                      handleInputChange(
                        "Cliente",
                        client.value,
                        event.target.value
                      )
                    }
                    error={Boolean(tabData.errors[client.value])}
                  >
                    <MenuItem value="Maschio">Maschio</MenuItem>
                    <MenuItem value="Femmina">Femmina</MenuItem>
                  </Select>
                  {tabData.errors[client.value] && (
                    <Typography color="error" variant="caption">
                      {tabData.errors[client.value]}
                    </Typography>
                  )}
                </FormControl>
              ) : client.value === "Nome" ? (
                <TextField
                  disabled={!client.editable}
                  label={`${client.value} ${!client.editable ? "" : "*"}`}
                  name={client.value}
                  value={rcDetails.infoAnag && rcDetails.infoAnag.nome}
                  error={Boolean(tabData.errors[client.value])}
                  helperText={tabData.errors[client.value]}
                />
              ) : client.value === "Cognome" ? (
                <TextField
                  disabled={!client.editable}
                  label={`${client.value} ${!client.editable ? "" : "*"}`}
                  name={client.value}
                  value={rcDetails.infoAnag && rcDetails.infoAnag.cognome}
                  error={Boolean(tabData.errors[client.value])}
                  helperText={tabData.errors[client.value]}
                />
              ) : client.value === "P. IVA" && rcDetails.infoAnag ? (
                <TextField
                  disabled={!client.editable}
                  label={`${client.value} ${!client.editable ? "" : "*"}`}
                  name={client.value}
                  value={`${rcDetails.infoPiva !== undefined ? rcDetails.infoPiva.RagioneSociale : tabData.values[client.value] } ${rcDetails.infoPiva !== undefined ? rcDetails.infoPiva.ComuneSedeLegale : tabData.values[client.value]}(${rcDetails.infoPiva !== undefined ? rcDetails.infoPiva.ProvinciaSedeLegale : tabData.values[client.value]}) `}
                  error={Boolean(tabData.errors[client.value])}
                  helperText={tabData.errors[client.value]}
                />
              ) : (
                <TextField
                  disabled={!client.editable}
                  label={`${client.value} ${!client.editable ? "" : "*"}`}
                  name={client.value}
                  value={tabData.values[client.value] || ""}
                  onChange={(event) =>
                    handleInputChange(
                      "Cliente",
                      client.value,
                      event.target.value
                    )
                  }
                  error={Boolean(tabData.errors[client.value])}
                  helperText={tabData.errors[client.value]}
                />
              )}
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Spacer mt={10} />
      <Divider />
      <Spacer mt={10} />
    </div>
  );
};

export default Cliente;
