import { CardContent } from "@mui/material";
import react from "react"
import { Card, Grid, Spacer, Typography, AboutIcon } from "../style";
import { Briefcase, Home, Mail, MapPin, Phone, User, Menu } from "react-feather"
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"

const Piva = ({data,className}) => {
    return (
        <Card mb={6} className={className}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Azienda
            </Typography>
            <Spacer mb={4} />
            <Grid container direction="row" alignItems="center" mb={2}>
              <Grid item>
                <AboutIcon>
                  <User />
                </AboutIcon>
              </Grid>
              <Grid item>Ragione: {data?.RagioneSociale} </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <AboutIcon>
                  <HomeWorkOutlinedIcon />
                </AboutIcon>
              </Grid>
              <Grid item>Comune Sede: {data?.ComuneSedeLegale} ({data?.ProvinciaSedeLegale})</Grid>
            </Grid>
          </CardContent>
        </Card>
      );
}

export default Piva
