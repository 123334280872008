import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Bar } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { rgba } from "polished";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 240px;
  width: 100%;
`;

const BarChart = ({ theme, barChartData }) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Totale",
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        hoverBackgroundColor: theme.palette.secondary.main,
        hoverBorderColor: theme.palette.secondary.main,
        data: [],
        barPercentage: 0.4,
        categoryPercentage: 0.5,
      },
      {
        label: "Totale",
        backgroundColor: rgba(theme.palette.secondary.main, 0.33),
        borderColor: rgba(theme.palette.secondary.main, 0.33),
        hoverBackgroundColor: rgba(theme.palette.secondary.main, 0.33),
        hoverBorderColor: rgba(theme.palette.secondary.main, 0.33),
        data: [],
        barPercentage: 0.4,
        categoryPercentage: 0.5,
        borderRadius: 6,
      },
    ],
  });

  useEffect(() => {
    if (barChartData) {
      const labels = barChartData.map((item) => item.Giorno);
      const dataValues = barChartData.map((item) => item.Tot);

      setData({
        labels,
        datasets: [
          {
            ...data.datasets[0],
            data: dataValues,
          },
          {
            ...data.datasets[1],
            data: [],
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barChartData, theme.palette.secondary.main]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: theme.palette.primary.main,
        anchor: "end",
        align: "start",
        offset: -20,
        formatter: (value) => value,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <Card mb={4}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Trend preventivi"
      />

      <CardContent>
        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart);
