import React, { useEffect, useState } from "react";
import { Typography, Grid, FormControl, Divider } from "../style";
import { TextField, CardContent } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGrid } from "@mui/x-data-grid";

const parseDate = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split("/");
  return new Date(year, month - 1, day);
};

const Veicolo = ({
  vehiclePlcholders,
  handleInputChange,
  tabData,
  className,
  rcDetails,
}) => {
  const [formData, setFormData] = useState([]);
  const [propertyTableData, setPropertyTableData] = useState([]);
  const [coverageTableData, setCoverageTableData] = useState([]);
  const [attestatoTableData, setAttestatoTableData] = useState([]);
  const [sinistriTableData, setSinistriTableData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    if (rcDetails && rcDetails.tabs && rcDetails.tabs.Veicolo) {
      const formDataFromAPI = rcDetails.tabs.Veicolo.generale.dataset || [];
      const propertyTableDataFromAPI = rcDetails.tabs.Veicolo.Proprietà.dataset || [];
      const coverageTableDataFromAPI = rcDetails.tabs.Veicolo.Coperture.dataset || [];
      const attestatoTableDataFromAPI = rcDetails.tabs.Veicolo.Attestato.dataset || [];
      const sinistriTableDataFromAPI = rcDetails.tabs.Veicolo.Sinistri.dataset || [];

      const addIdToRows = (rows) =>
        rows.map((row, index) => ({ ...row, id: index }));

      setFormData(formDataFromAPI);
      setPropertyTableData(addIdToRows(propertyTableDataFromAPI));
      setCoverageTableData(addIdToRows(coverageTableDataFromAPI));
      setAttestatoTableData(addIdToRows(attestatoTableDataFromAPI));
      setSinistriTableData(addIdToRows(sinistriTableDataFromAPI));

      const initialValues = {};
      vehiclePlcholders.forEach((vehicle) => {
        let value = vehicle.value;

        if (
          vehicle.label === "Data Acquisto" ||
          vehicle.label === "Data Immatricolazione" ||
          vehicle.label === "Inizio copertura" ||
          vehicle.label === "Fine copertura"
        ) {
          value = parseDate(vehicle.value);
        }

        initialValues[vehicle.label] = value;
      });

      handleInputChange("Veicolo", initialValues);
    }
  }, [rcDetails, vehiclePlcholders, handleInputChange]);

  const handleDateChange = (date, fieldName) => {
    handleInputChange(
      "Veicolo",
      fieldName,
      date ? date.toLocaleDateString("it-IT") : ""
    );
  };

  const renderInputField = (vehicle) => {
    const value = tabData.values[vehicle.label] || vehicle.value || null;

    if (
      vehicle.label === "Data Acquisto" ||
      vehicle.label === "Data Immatricolazione" ||
      vehicle.label === "Inizio copertura" ||
      vehicle.label === "Fine copertura"
    ) {
      return (
        <DatePicker
          label={`${vehicle.label} ${!vehicle.editable ? "" : "*"}`}
          name={vehicle.label}
          disabled={!Boolean(vehicle.editable)}
          value={value instanceof Date ? value : parseDate(vehicle.value)}
          onChange={(date) => handleDateChange(date, vehicle.label)}
          renderInput={(params) => <TextField {...params} fullWidth />}
          inputFormat="dd/MM/yyyy"
        />
      );
    } else {
      return (
        <TextField
          disabled={!vehicle.editable}
          label={`${vehicle.label} ${!vehicle.editable ? "" : "*"}`}
          name={vehicle.label}
          value={value}
          onChange={(event) =>
            handleInputChange("Veicolo", vehicle.label, event.target.value)
          }
          fullWidth
        />
      );
    }
  };

  const generateRowId = (row) => row.id;

  // Colonne per la tabella "Proprietà"
  const propertyColumns = [
    { field: "Targa", headerName: "Targa", minWidth: 150, flex: 1 },
    { field: "Telaio", headerName: "Telaio", minWidth: 150, flex: 1 },
    { field: "Tipo veicolo", headerName: "Tipo Veicolo", minWidth: 150, flex: 1 },
    { field: "Provenienza", headerName: "Provenienza", minWidth: 150, flex: 1 },
    { field: "Data immatricolazione", headerName: "Data Immatricolazione", minWidth: 150, flex: 1 },
    { field: "Data operazione", headerName: "Data Operazione", minWidth: 150, flex: 1 },
    { field: "Intestatario", headerName: "Intestatario", minWidth: 150, flex: 1 },
    { field: "Movimento", headerName: "Movimento", minWidth: 150, flex: 1 },
    { field: "Causale", headerName: "Causale", minWidth: 150, flex: 1 },
    { field: "Residenza", headerName: "Residenza", minWidth: 150, flex: 1 },
  ];

  // Colonne per la tabella "Coperture"
  const coverageColumns = [
    { field: "TARGA", headerName: "Targa", minWidth: 150, flex: 1 },
    { field: "Impresa", headerName: "Impresa", minWidth: 150, flex: 1 },
    { field: "Inizio copertura", headerName: "Inizio Copertura", minWidth: 150, flex: 1 },
    { field: "Fine copertura", headerName: "Fine Copertura", minWidth: 150, flex: 1 },
    { field: "Codice IUR", headerName: "Codice IUR", minWidth: 150, flex: 1 },
    { field: "N. polizza", headerName: "Numero Polizza", minWidth: 150, flex: 1 },
    { field: "Scadenza polizza", headerName: "Scadenza Polizza", minWidth: 150, flex: 1 },
    { field: "Agente", headerName: "Agente", minWidth: 150, flex: 1 },
  ];

  // Colonne per la tabella "Attestato"
  const attestatoColumns = [
    { field: "Impresa", headerName: "Impresa", minWidth: 150, flex: 1 },
    { field: "Scadenza", headerName: "Scadenza", minWidth: 150, flex: 1 },
    { field: "Tariffa", headerName: "Tariffa", minWidth: 150, flex: 1 },
    { field: "N_Polizza", headerName: "Numero Polizza", minWidth: 150, flex: 1 },
    { field: "C_Cognome", headerName: "Cognome Contraente", minWidth: 150, flex: 1 },
    { field: "C_Nome", headerName: "Nome Contraente", minWidth: 150, flex: 1 },
    { field: "C_CF", headerName: "CF Contraente", minWidth: 150, flex: 1 },
    { field: "A_Cognome", headerName: "Cognome Assicurato", minWidth: 150, flex: 1 },
    { field: "A_Nome", headerName: "Nome Assicurato", minWidth: 150, flex: 1 },
    { field: "A_CF", headerName: "CF Assicurato", minWidth: 150, flex: 1 },
    { field: "Franchigia_Numero", headerName: "Franchigia Numero", minWidth: 150, flex: 1 },
    { field: "Franchigia_Importi", headerName: "Franchigia Importi", minWidth: 150, flex: 1 },
    { field: "Impresa_Proc", headerName: "Impresa Proc", minWidth: 150, flex: 1 },
    { field: "Impresa_Ass", headerName: "Impresa Ass", minWidth: 150, flex: 1 },
    { field: "CU_Prov", headerName: "CU Prov", minWidth: 150, flex: 1 },
    { field: "CU_Ass", headerName: "CU Ass", minWidth: 150, flex: 1 },
  ];

  // Colonne per la tabella "Sinistri"
  const sinistriColumns = [
    { field: "anno", headerName: "Anno", minWidth: 150, flex: 1 },
    { field: "principali", headerName: "Principali", minWidth: 150, flex: 1 },
    { field: "principaliCose", headerName: "Principali Cose", minWidth: 150, flex: 1 },
    { field: "principaliPersone", headerName: "Principali Persone", minWidth: 150, flex: 1 },
    { field: "paritari", headerName: "Paritari", minWidth: 150, flex: 1 },
    { field: "paritariPersone", headerName: "Paritari Persone", minWidth: 150, flex: 1 },
    { field: "paritariCose", headerName: "Paritari Cose", minWidth: 150, flex: 1 },
    { field: "paritariMisti", headerName: "Paritari Misti", minWidth: 150, flex: 1 },
  ];

  return (
    <Grid container spacing={2}>
      {vehiclePlcholders.map((vehicle, index) => (
        <Grid item xs={6} key={index}>
          <FormControl fullWidth>{renderInputField(vehicle)}</FormControl>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Divider>
          <Typography variant="h6">Proprietà</Typography>
        </Divider>
        <CardContent>
          <DataGrid
            rows={propertyTableData}
            columns={propertyColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            getRowId={generateRowId}
          />
        </CardContent>
      </Grid>

      <Grid item xs={12}>
        <Divider>
          <Typography variant="h6">Coperture</Typography>
        </Divider>
        <CardContent>
          <DataGrid
            rows={coverageTableData}
            columns={coverageColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            getRowId={generateRowId}
          />
        </CardContent>
      </Grid>

      <Grid item xs={12}>
        <Divider>
          <Typography variant="h6">Attestato</Typography>
        </Divider>
        <CardContent>
          <DataGrid
            rows={attestatoTableData}
            columns={attestatoColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            getRowId={generateRowId}
          />
        </CardContent>
      </Grid>

      <Grid item xs={12}>
        <Divider>
          <Typography variant="h6">Sinistri</Typography>
        </Divider>
        <CardContent>
          <DataGrid
            rows={sinistriTableData}
            columns={sinistriColumns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            getRowId={generateRowId}
          />
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default Veicolo;
