import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { spacing } from "@mui/system";
import Modal from "./nuovoPreventivo";
import { dateOptions, transformFilterArrayData } from "./constant";
import useAuth from "../../../hooks/useAuth";
import CircularProgress from "@mui/material/CircularProgress";
import Filters from "./Filters";
import { useGetColumnsModelDataUsers } from "./columns";
import DoughnutChart from "./DoughnutChart";
import BarChart from "./BarChart";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function RCAuto() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [doughnutChartData, setDoughnutChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [rcData, setRcData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterData, setFilterData] = useState({
    filterFor: "",
    valueFor: "",
    filterDate: 3,
  });
  const [loading, setLoading] = useState(true);
  const [isTableChanging, setIsTableChanging] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isApplyFiltersButtonDisabled, setIsApplyFiltersButtonDisabled] =
    useState(true);
  const [searchOptions, setSearchOptions] = useState(null);
  const [displaySecondSelect, setDisplaySecondSelect] = useState(false);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [filtersActive, setFiltersActive] = useState(isDesktop);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getRcData, getData } = useAuth();
  const columns = useGetColumnsModelDataUsers();

  useEffect(() => {
    if (filterData.filterFor) {
      setIsApplyFiltersButtonDisabled(Boolean(!filterData.valueFor));
    } else {
      setIsApplyFiltersButtonDisabled(Boolean(!filterData.filterDate));
    }
  }, [filterData.filterDate, filterData.filterFor, filterData.valueFor]);

  useEffect(() => {
    setIsTableChanging(true);
    getRcData(
      rowsPerPage,
      pageNumber,
      filterData.filterFor,
      filterData.valueFor,
      filterData.filterDate
    )
      .then((response) => {
        if (response.status === 1) {
          setDoughnutChartData(response?.data?.graphCircleData);
          setBarChartData(response?.data?.graphBarData);
          setTotalRows(response?.data?.totalRows);
          setRcData(response?.data?.dataset);
          setIsTableChanging(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, rowsPerPage]);

  useEffect(() => {
    getData({ action: "rcAutoGetFilter" }).then((data) => {
      if (data.status === 1) {
        setSearchOptions([{ value: "", label: "Nessuno" }, ...data.data]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      filterData.filterFor === 3 ||
      filterData.filterFor === 4 ||
      filterData.filterFor === 5
    ) {
      getData({
        action: "rcAutoGetFilterValue",
        idFilter: filterData.filterFor,
      }).then((data) => {
        if (data.status === 1) {
          const list = transformFilterArrayData(data.data);
          setSecondSelectOptions(list);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData.filterFor]);

  useEffect(() => {
    if (filterData.filterFor > 2) {
      setDisplaySecondSelect(true);
    } else {
      setDisplaySecondSelect(false);
    }
  }, [filterData.filterFor]);

  const handlePageChange = (newPage) => {
    if (isTableChanging) {
      return;
    } else {
      setPageNumber(newPage + 1);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPageNumber(1);
  };

  const switchFilterActive = () => {
    setFiltersActive((prev) => !prev);
  };

  const handleRemoveFilters = () => {
    setIsTableChanging(true);
    setFilterData({
      filterFor: "",
      valueFor: "",
      filterDate: 3,
    });

    getRcData(rowsPerPage, pageNumber, "", "", 3)
      .then((response) => {
        if (response.status === 1) {
          setDoughnutChartData(response?.data?.graphCircleData);
          setBarChartData(response?.data?.graphBarData);
          setTotalRows(response?.data?.totalRows);
          setRcData(response?.data?.dataset);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsApplyFiltersButtonDisabled(false);
        setFiltersLoading(false);
        setLoading(false);
        setIsTableChanging(false);
      });
  };

  const handleApplyFilters = () => {
    setFiltersLoading(true);
    setIsTableChanging(true);
    setIsApplyFiltersButtonDisabled(true);
    getRcData(
      rowsPerPage,
      pageNumber,
      filterData.filterFor,
      filterData.valueFor,
      filterData.filterDate
    )
      .then((response) => {
        if (response.status === 1) {
          setDoughnutChartData(response?.data?.graphCircleData);
          setBarChartData(response?.data?.graphBarData);
          setTotalRows(response?.data?.totalRows);
          setRcData(response?.data?.dataset);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsApplyFiltersButtonDisabled(false);
        setFiltersLoading(false);
        setIsTableChanging(false);
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "filterFor") {
      setFilterData((prev) => ({
        ...prev,
        filterFor: value,
        valueFor: "",
        filterDate: "",
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  return (
    <React.Fragment>
      <Helmet title="RC Auto" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" gutterBottom display="inline">
          RC Auto
        </Typography>

        <Button variant="contained" onClick={() => setIsModalOpen(true)}>
          Nuovo preventivo
        </Button>
      </Box>

      <Divider my={6} />

      {loading ? (
        <Box
          sx={{
            width: "calc(100vw - 40px)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          {/* Charts */}
          <Grid
            container
            wrap="wrap"
            sx={{
              margin: isDesktop ? "0 -16px" : "0",
              padding: "0",
              width: isDesktop ? "calc(100% + 16px)" : "100%",
            }}
            spacing={isDesktop ? 4 : 0}
          >
            <Grid item lg={4} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
              <DoughnutChart doughnutChartData={doughnutChartData} />
            </Grid>
            <Grid item lg={8} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
              <BarChart barChartData={barChartData} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <>
              <Card sx={{ marginBottom: "1rem" }}>
                <Modal
                  open={isModalOpen}
                  setOpen={setIsModalOpen}
                />
                <Filters
                  filtersActive={filtersActive}
                  switchFilterActive={switchFilterActive}
                  searchOptions={searchOptions}
                  filterData={filterData}
                  handleChange={handleChange}
                  displaySecondSelect={displaySecondSelect}
                  secondSelectOptions={secondSelectOptions}
                  dateOptions={dateOptions}
                  handleApplyFilters={handleApplyFilters}
                  isApplyFiltersButtonDisabled={isApplyFiltersButtonDisabled}
                  filtersLoading={filtersLoading}
                  handleRemoveFilters={handleRemoveFilters}
                />
              </Card>

              <Card mb={6}>
                <Paper>
                  <Box sx={{ width: "100%" }}>
                    <DataGrid
                      autoHeight
                      pageSize={rowsPerPage}
                      onPageSizeChange={handlePageSizeChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      pagination
                      paginationMode="server"
                      rowCount={totalRows}
                      page={pageNumber - 1}
                      onPageChange={(params) => handlePageChange(params)}
                      getRowId={(usersList) => usersList.idUID}
                      rows={rcData}
                      columns={columns}
                      editMode="row"
                      allowUpdating={true}
                      experimentalFeatures={{ newEditingApi: true }}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      localeText={{ noRowsLabel: "Non ci sono risultati" }}
                      sx={{
                        "& .MuiDataGrid-footerContainer": {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                        },
                      }}
                    />
                  </Box>
                </Paper>
              </Card>
            </>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default RCAuto;
