import React, { useState, useEffect, useRef } from "react";
import {
  useMediaQuery,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Fade,
  Divider,
} from "@mui/material";
import Contatti from "./Contatti";
import AltreInfo from "./AltreInfo";
import useGooglePlaces from "./useGooglePlaces";
import useAuth from "../../../../hooks/useAuth";
import AddressSection from "./AddressSection";
import {
  mapAddressComponentsToState,
  updateStateFromResponse,
  validateTarga,
} from "./helpers";
import {
  generalErrorMessage,
  initialState,
  savedDataMessage,
} from "./constant";
import { showSnackbar } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

function Modal({ open, setOpen }) {
  const isUnder600px = useMediaQuery("(max-width:600px)");
  const isUnder1000px = useMediaQuery("(max-width:1000px)");
  const targaInputRef = useRef(null);

  const [data, setData] = useState(initialState);
  const [targaSearchResult, setTargaSearchResult] = useState(false);
  const [targaError, setTargaError] = useState();
  const [fullAddress, setFullAddress] = useState("");
  const [otherInfoData, setOtherInfoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isTargaInputDisabled, setIsTargaInputDisabled] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(true);
  const [isAltreInfoVisible, setIsAltreInfoVisible] = useState(false);
  const [isContattiVisible, setIsContattiVisible] = useState(true);
  const [isAddressVisible, setIsAddressVisible] = useState(true);
  const [, setIndirizzoRicercato] = useState("");
  const { rcAutoNewUpdate } = useAuth();
  const dispatch = useDispatch();

  const {
    searching,
    options,
    value,
    setValue,
    debouncedSetInputValue,
    getPlaceDetails,
  } = useGooglePlaces();

  useEffect(() => {
    if (open && targaInputRef.current) {
      targaInputRef.current.focus();
    }
  }, [open]);

  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  const handleClose = (_, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: name === "targa" ? value.toUpperCase() : value,
    }));
    setTargaError("");
  };

  const handleRicerca = () => {
    if (!targaSearchResult) {
      if (!validateTarga(data.targa)) {
        setTargaError("Formato targa non valido.");
        return;
      }

      setLoading(true);
      setShowCloseButton(false);
      rcAutoNewUpdate({ targa: data.targa, idUID: data.idUid })
        .then((response) => {
          if (response.status === 1) {
            setTargaSearchResult(true);
            updateStateFromResponse(response, setFullAddress, setData);
            setOtherInfoData(response.altreInformazioni);
            setLoading(false);
            setShowForm(true);
            setIsTargaInputDisabled(true);
          } else {
            handleSnackbar(response.message || generalErrorMessage, "error");
          }
        })
        .catch((err) => {
          handleSnackbar(err.message || generalErrorMessage, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      rcAutoNewUpdate(data, "updateDataForm")
        .then((response) => {
          if (response.status === 1) {
            setOpen(false);
            handleSnackbar(response.message || savedDataMessage, "success");
            setData(initialState);
            setIsTargaInputDisabled(false);
            setShowForm(false);
            setFullAddress("");
            setTargaSearchResult(false);
          } else {
            handleSnackbar(response.message || generalErrorMessage, "error");
          }
        })
        .catch((err) => {
          handleSnackbar(err.message || generalErrorMessage, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRicerca();
  };

  const toggleAltreInfoVisibility = () => {
    setIsAltreInfoVisible((prev) => !prev);
  };

  const toggleContattiVisibility = () => {
    setIsContattiVisible((prev) => !prev);
  };

  const toggelAddressVisibility = () => {
    setIsAddressVisible((prev) => !prev);
  };

  const handleAddressChange = (newValue) => {
    setValue(newValue);
    if (newValue && newValue.place_id) {
      getPlaceDetails(newValue.place_id, (place) => {
        mapAddressComponentsToState(place.address_components, setData);
        setIndirizzoRicercato(newValue.description);
      });
    }
  };

  let maxWidth;
  if (isUnder600px) {
    maxWidth = "xs";
  } else if (isUnder1000px) {
    maxWidth = "sm";
  } else {
    maxWidth = "md";
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          maxWidth: showForm ? maxWidth : "400px",
          width: "100%",
          overflow: "hiden",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        {loading ? "Ricerca in corso..." : "Nuova Ricerca"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: maxWidth === "md" ? "row" : "column",
                  marginBottom: "8px",
                  marginTop: "8px",
                }}
              >
                <TextField
                  id={"targa-input"}
                  sx={{ maxWidth: "415px", width: "100%" }}
                  disabled={isTargaInputDisabled}
                  size="small"
                  label="Targa"
                  name="targa"
                  value={data.targa}
                  onChange={handleChange}
                  inputRef={targaInputRef}
                  error={Boolean(targaError)}
                  helperText={targaError}
                  autoFocus
                />
              </Box>
              {showForm && (
                <Fade in={showForm}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Divider sx={{ margin: "16px 0" }} />
                    <AddressSection
                      value={value}
                      options={options}
                      searching={searching}
                      handleAddressChange={handleAddressChange}
                      debouncedSetInputValue={debouncedSetInputValue}
                      fullAddress={fullAddress}
                      toggelAddressVisibility={toggelAddressVisibility}
                      isAddressVisible={isAddressVisible}
                    />
                    <Divider sx={{ margin: "16px 0" }} />
                    <Contatti
                      data={data}
                      isContattiVisible={isContattiVisible}
                      toggleContattiVisibility={toggleContattiVisibility}
                      maxWidth={maxWidth}
                      handleChange={handleChange}
                    />
                    <Divider sx={{ margin: "16px 0" }} />
                    <AltreInfo
                      data={data}
                      otherInfoData={otherInfoData}
                      isAltreInfoVisible={isAltreInfoVisible}
                      toggleAltreInfoVisibility={toggleAltreInfoVisibility}
                      maxWidth={maxWidth}
                      handleChange={handleChange}
                    />
                    <Divider sx={{ marginTop: "16px" }} />
                  </Box>
                </Fade>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {showCloseButton && (
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              sx={{ marginRight: "8px" }}
            >
              Chiudi
            </Button>
          )}
          <Button
            disabled={loading || !data.targa}
            type="submit"
            color="primary"
            variant="contained"
            sx={{ marginRight: "16px" }}
          >
            {showForm ? "Salva" : "Ricerca"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Modal;
