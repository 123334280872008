export const initialState = {
  targa: "",
  indirizzoVia: "",
  indirizzo: "",
  indirizzoCivico: "",
  indirizzoCAP: "",
  indirizzoComune: "",
  indirizzoProvincia: "",
  email: "",
  telefono: "",
  cellulare: "",
  titoloStudio: "",
  professione: "",
  statoCivile: "",
  tipoGuida: "",
  antifurto: "",
  ricovero: "",
  usoVeicolo: "",
  figli: "",
};

export const generalErrorMessage = "Qualcosa e andato storto."

export const savedDataMessage = "I dati sono stati salvati."

export const rebootMessage = "Metti azione eseguita correttamente."