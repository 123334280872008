import React from 'react'

const Preventivi = () => {
  return (
    <div>
      Preventivi
    </div>
  )
}

export default Preventivi
