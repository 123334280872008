import React, { useEffect, useState } from "react";
import {
  FilterList as FilterIcon,
  FilterListOff as FilterOffIcon,
} from "@mui/icons-material";

import {
  useTheme,
  Box,
  Button,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import CustomSelect from "../../components/CustomSelect";
import { CustomSelectMenuProps } from "./constant";
import CircularProgress from "@mui/material/CircularProgress";

function Filters({
  filtersActive,
  switchFilterActive,
  searchOptions,
  filterData,
  handleChange,
  displaySecondSelect,
  secondSelectOptions,
  dateOptions,
  handleApplyFilters,
  isApplyFiltersButtonDisabled,
  filtersLoading,
  handleRemoveFilters,
}) {
  const theme = useTheme();
  const [canClearFilters, setCanClearFilters] = useState(false);

  useEffect(() => {
    if (
      filterData.filterFor ||
      filterData.valueFor ||
      filterData.filterDate > 3
    ) {
      setCanClearFilters(true);
    } else {
      setCanClearFilters(false);
    }
  }, [filterData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "16px 16px",
        [theme.breakpoints.down("md")]: {
          padding: "1rem 1rem 0.5rem 1rem",
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          alignItems: "start",
          padding: "1rem",
        },
      }}
    >
      {!filtersActive ? (
        <Stack
          direction="row"
          sx={{ cursor: "pointer", userSelect: "none" }}
          onClick={switchFilterActive}
        >
          <FilterIcon
            sx={{
              marginRight: "16px",
              [theme.breakpoints.down("md")]: {
                marginBottom: "8px",
              },
              [theme.breakpoints.down("sm")]: {
                marginBottom: "4px",
              },
            }}
          />
          <Typography variant="h4" mr={4}>
            Filtra
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction="row"
          sx={{ cursor: "pointer", userSelect: "none" }}
          onClick={switchFilterActive}
        >
          <FilterOffIcon
            sx={{
              marginRight: "16px",
              [theme.breakpoints.down("md")]: {
                marginBottom: "8px",
              },
              [theme.breakpoints.down("sm")]: {
                marginBottom: "4px",
              },
            }}
          />
          <Typography variant="h4" mr={4}>
            Filtra
          </Typography>
        </Stack>
      )}

      {filtersActive && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              flexWrap: "nowrap",
              alignItems: "end",
              width: "100%",
            },
          }}
        >
          <CustomSelect
            disabled={
              Boolean(!searchOptions) || Boolean(searchOptions?.length < 1)
            }
            label={"Ricerca per"}
            name="filterFor"
            options={searchOptions}
            value={filterData.filterFor}
            size="small"
            sx={{
              width: "200px",
              marginRight: "1rem",
              minWidth: "200px",
              [theme.breakpoints.down("md")]: {
                marginBottom: "8px",
              },
              [theme.breakpoints.down("sm")]: {
                marginBottom: "0",
                margin: "8px 0",
                width: "100%",
              },
            }}
            onChange={(event) => handleChange(event)}
            MenuProps={CustomSelectMenuProps}
          />

          {displaySecondSelect ? (
            <CustomSelect
              disabled={
                Boolean(!secondSelectOptions) ||
                Boolean(secondSelectOptions?.lenght < 1) ||
                Boolean(!filterData.filterFor)
              }
              label={"Valore"}
              name="valueFor"
              size="small"
              options={secondSelectOptions}
              value={filterData.valueFor}
              sx={{
                width: "200px",
                marginRight: "1rem",
                minWidth: "200px",
                [theme.breakpoints.down("md")]: {
                  marginBottom: "8px",
                },
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "0",
                  margin: "8px 0",
                  width: "100%",
                },
              }}
              onChange={(event) => handleChange(event)}
              MenuProps={CustomSelectMenuProps}
            />
          ) : (
            <TextField
              size="small"
              disabled={Boolean(!filterData.filterFor)}
              label={"Valore"}
              name="valueFor"
              value={filterData.valueFor}
              sx={{
                width: "200px",
                marginRight: "1rem",
                minWidth: "200px",
                [theme.breakpoints.down("md")]: {
                  marginBottom: "8px",
                },
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "0",
                  margin: "8px 0",
                  width: "100%",
                },
              }}
              onChange={(event) => handleChange(event)}
            />
          )}
          <CustomSelect
            label={"Filtra per data"}
            disabled={Boolean(filterData.filterFor)}
            name="filterDate"
            size="small"
            options={dateOptions}
            value={filterData.filterDate}
            sx={{
              width: "200px",
              marginRight: "1rem",
              minWidth: "200px",
              [theme.breakpoints.down("md")]: {
                marginBottom: "8px",
              },
              [theme.breakpoints.down("sm")]: {
                marginBottom: "0",
                margin: "8px 0",
                width: "100%",
              },
            }}
            onChange={(event) => handleChange(event)}
            MenuProps={CustomSelectMenuProps}
          />
          <Box
            sx={{
              marginBottom: "1px",
              [theme.breakpoints.down("md")]: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "row",
              },
            }}
          >
            {canClearFilters && (
              <Button
                variant="outlined"
                onClick={handleRemoveFilters}
                sx={{
                  marginBottom: "1px",
                  marginRight: "16px",
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "8px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: "0px",
                    marginTop: "8px",
                  },
                }}
              >
                Rimuovi filtri
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleApplyFilters}
              disabled={Boolean(isApplyFiltersButtonDisabled)}
              sx={{
                marginBottom: "1px",
                [theme.breakpoints.down("md")]: {
                  marginBottom: "8px",
                },
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "0px",
                  marginTop: "8px",
                },
              }}
            >
              {filtersLoading ? <CircularProgress size={22} /> : "Cerca"}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Filters;
