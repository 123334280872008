const ITEM_HEIGHT = 36;
const MOBILE_ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_ITEMS = 5;
export const CustomSelectMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: {
        xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
        sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
      },
    },
  },
};

export const transformFilterArrayData = (data) => {
  return data.map((item) => {
    let value = "";
    let label = "";

    if (item.idUID) {
      value = item.idUID;
      label = item.Utente;
    } else if (item.ID) {
      value = item.ID;
      if (item.Utente) {
        label = item.Utente;
      } else if (item.Azienda) {
        label = item.Azienda;
      } else if (item.Stato) {
        label = item.Stato;
      }
    }

    return { value, label };
  });
};

export const dateOptions = [
  { value: 3, label: "3 giorni" },
  { value: 7, label: "1 settimana" },
  { value: 30, label: "1 mese" },
];
