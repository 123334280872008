import { CardContent } from "@mui/material";
import { Card, Grid, Spacer, Typography, AboutIcon } from "../style";
import { Briefcase, Home, Mail, MapPin, Phone, User, Menu } from "react-feather";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"

function UserNE({data,piva,className}) {
  return (
    <Card mb={6}className={className}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {piva ? "Legale" : "Cliente"}
        </Typography>
        <Spacer mb={4} />
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <User />
            </AboutIcon>
          </Grid>
          <Grid item>Nome: {data?.nome} {data?.cognome}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <AboutIcon>
              <HomeWorkOutlinedIcon />
            </AboutIcon>
          </Grid>
          <Grid item>Comune Residenza: {data?.ComuneResidenza} ({data?.ProvinciaResidenza})</Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default UserNE;
