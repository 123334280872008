import { CardContent } from "@mui/material";
import { Card, Grid, Spacer, Typography, AboutIcon } from "../style";
import { Briefcase, Home, MapPin,Code } from "react-feather";
import { CarRepairOutlined, InfoOutlined, ModelTrainingOutlined, PinOutlined } from "@mui/icons-material";

function VehicleNE({data,className}) {
  return (
    <Card mb={6} className={className}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Veicolo
        </Typography>
        <Spacer mb={4} />
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <PinOutlined />
            </AboutIcon>
          </Grid>
          <Grid item>Targa: {data?.targa}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <InfoOutlined />
            </AboutIcon>
          </Grid>
          <Grid item>Marca: {data?.marca}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <ModelTrainingOutlined />
            </AboutIcon>
          </Grid>
          <Grid item>Modello: {data?.modello}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <CarRepairOutlined />
            </AboutIcon>
          </Grid>
          <Grid item>Allestim.: {data?.Allestimento}</Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default VehicleNE;
