import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import useAuth from "../hooks/useAuth";
import {
  Activity,
  List,
  Book,
  CheckSquare,
  Layers,
  Users,
  Clipboard,
  Bell,
  Briefcase,
  Folder,
  Grid,
} from "react-feather";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMenuData } from "../redux/slices/sidebarMenu";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { datiMenu } = useSelector((state) => state.sidebarMenu);
  const dispatch = useDispatch();
  const { getData } = useAuth();
  const router = useLocation();
  const currentRoute = router.pathname;
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setMobileOpen(false);
  }, [currentRoute]);

  useEffect(() => {
    setMobileOpen(false);
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem("token");
        if (token) {
          const response = await getData({ action: "menu" });
          console.log(response);
          const list = response.data;
          if (response.status === 0) {
            console.log("Failed to retrive the menu data");
            navigate("/auth/sign-in");
            return;
          }
          for (let i = 0; i < response.data?.length; i++) {
            list[i].icon = stringIconObject(list[i].icon);
            for (let ii = 0; ii < list[i].children.length; ii++) {
              list[i].children[ii].icon = stringIconObject(
                list[i].children[ii].icon
              );
            }
          }
          dispatch(setMenuData([{ pages: list }]));
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
    // datiMenu.length === 0 && fetchData();
    //datiMenu.length
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();

  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer style={{ width: 0 }}>
        <Box sx={{ display: { xs: "none", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={datiMenu}
            theme={theme}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} theme={theme} />
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
    </Root>
  );
};

function stringIconObject(name) {
  switch (name) {
    case "activity":
      return Activity;
    case "list":
      return List;
    case "book":
      return Book;
    case "checksquare":
      return CheckSquare;
    case "layers":
      return Layers;
    case "users":
      return Users;
    case "clipboard":
      return Clipboard;
    case "bell":
      return Bell;
    case "briefcase":
      return Briefcase;
    case "folder":
      return Folder;
    case "grid":
      return Grid;
    default:
      return null;
  }
}

export default Dashboard;
